import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  ProductDetailHeader,
  CoreFeatureSection,
  ListCheckBulletsSection,
  CTASection,
} from "../components/blocks";

export default function productPageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.sanityProduct;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.productTitle} />
      <ProductDetailHeader
        title={pageData.header?.title?.localized}
        subTitle={pageData.header?.subTitle?.localized}
        description={pageData.header?.description?.localized}
        coverImageUrl={pageData.header?.coverImage?.asset?.fluid?.src}
      />
      <CoreFeatureSection
        title={pageData.coreFeature1?.leadText?.title?.localized}
        subTitle={pageData.coreFeature1?.leadText?.subTitle?.localized}
        descriptionRichText={pageData.coreFeature1?.leadText?.descriptionRichText ?? {}}
        coverImageUrl={pageData.coreFeature1?.mainImage?.asset?.fluid?.src}
        bulletItems={pageData.coreFeature1?.bulletItems ?? []}
        backgroundColor={theme.colorsTw.bg_bg2}
      />
      <CoreFeatureSection
        title={pageData.coreFeature2?.leadText?.title?.localized}
        subTitle={pageData.coreFeature2?.leadText?.subTitle?.localized}
        descriptionRichText={pageData.coreFeature2?.leadText?.descriptionRichText ?? {}}
        coverImageUrl={pageData.coreFeature2?.mainImage?.asset?.fluid?.src}
        bulletItems={pageData.coreFeature2?.bulletItems ?? []}
        backgroundColor={theme.colorsTw.bg_bg1}
        isFlipped={true}
      />
      <CoreFeatureSection
        title={pageData.coreFeature3?.leadText?.title?.localized}
        subTitle={pageData.coreFeature3?.leadText?.subTitle?.localized}
        descriptionRichText={pageData.coreFeature3?.leadText?.descriptionRichText ?? {}}
        coverImageUrl={pageData.coreFeature3?.mainImage?.asset?.fluid?.src}
        bulletItems={pageData.coreFeature3?.bulletItems ?? []}
        backgroundColor={theme.colorsTw.bg_bg2}
      />
      <ListCheckBulletsSection
        title={pageData.checkBulletsList?.leadText?.title?.localized}
        subTitle={pageData.checkBulletsList?.leadText?.subTitle?.localized}
        descriptionRichText={pageData.checkBulletsList?.leadText?.descriptionRichText ?? {}}
        coverImageUrl={pageData.checkBulletsList?.mainImage?.asset?.fluid?.src}
        bulletItems={pageData.checkBulletsList?.bulletItems ?? []}
        backgroundColor={theme.colorsTw.bg_bg1}
      />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String!, $id: String!) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    sanityProduct(id: { eq: $id }) {
      productTitle
      header {
        title {
          localized(locale: $locale)
        }
        subTitle {
          localized(locale: $locale)
        }
        description {
          localized(locale: $locale)
        }
        coverImage {
          ...Image
        }
      }
      coreFeature1 {
        ...BulletsTextBlockSection
      }
      coreFeature2 {
        ...BulletsTextBlockSection
      }
      coreFeature3 {
        ...BulletsTextBlockSection
      }
      checkBulletsList {
        ...BulletsTextBlockSection
      }
    }
  }
`;
